import './kickback_svg.css';

function KickbackSVG() {
  return (
<div className="wrapper">
  <svg className='kickback-svg' id='kick_id' width="711" height="112" viewBox="0 0 711 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.70001 98.56H16.2V13.72H5.70001V7.28001H38.74V13.72H28.24V60.48H28.8L41.82 45.78L71.22 13.72H60.02V7.28001H90.12V13.72H80.74L50.5 46.34L84.24 98.56H93.9V105H60.44V98.56H70.38L42.1 55.02L28.24 70V98.56H38.74V105H5.70001V98.56Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M104.684 98.56H115.184V13.72H104.684V7.28001H137.724V13.72H127.224V98.56H137.724V105H104.684V98.56Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M193.263 106.68C187.103 106.68 181.503 105.513 176.463 103.18C171.423 100.847 167.129 97.4867 163.583 93.1C160.036 88.7134 157.283 83.3933 155.323 77.14C153.363 70.8867 152.383 63.8867 152.383 56.14C152.383 48.02 153.363 40.8333 155.323 34.58C157.283 28.3267 159.989 23.0533 163.443 18.76C166.989 14.4667 171.236 11.2 176.183 8.96C181.129 6.72 186.59 5.60001 192.563 5.60001C198.63 5.60001 203.669 6.62667 207.683 8.68001C211.789 10.64 214.496 13.1133 215.803 16.1H216.363V7.28001H226.443V35.84H216.363V28.56C216.363 23.8933 214.31 20.16 210.203 17.36C206.096 14.4667 200.729 13.02 194.103 13.02C189.156 13.02 184.863 13.9533 181.223 15.82C177.676 17.5933 174.736 20.0667 172.403 23.24C170.163 26.32 168.483 29.96 167.363 34.16C166.336 38.2667 165.823 42.6067 165.823 47.18V64.12C165.823 69.2533 166.476 73.8733 167.783 77.98C169.183 82.0867 171.143 85.6333 173.663 88.62C176.276 91.5133 179.449 93.7533 183.183 95.34C186.916 96.9267 191.163 97.72 195.923 97.72C202.55 97.72 208.103 95.9933 212.583 92.54C217.063 89.0867 220.329 84.7 222.383 79.38L227.843 82.04C227.003 84.7467 225.649 87.5467 223.783 90.44C221.916 93.3334 219.536 95.9933 216.643 98.42C213.843 100.847 210.483 102.853 206.563 104.44C202.643 105.933 198.209 106.68 193.263 106.68Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M244.001 98.56H254.501V13.72H244.001V7.28001H277.041V13.72H266.541V60.48H267.101L280.121 45.78L309.521 13.72H298.321V7.28001H328.421V13.72H319.041L288.801 46.34L322.541 98.56H332.201V105H298.741V98.56H308.681L280.401 55.02L266.541 70V98.56H277.041V105H244.001V98.56Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M342.985 98.56H353.485V13.72H342.985V7.28001H389.745C397.958 7.28001 404.352 9.38001 408.925 13.58C413.592 17.78 415.925 23.38 415.925 30.38C415.925 33.8333 415.412 36.9133 414.385 39.62C413.358 42.3267 411.958 44.66 410.185 46.62C408.505 48.58 406.545 50.1667 404.305 51.38C402.159 52.5933 399.919 53.3867 397.585 53.76V54.18C400.012 54.3667 402.532 54.9733 405.145 56C407.759 57.0267 410.138 58.52 412.285 60.48C414.432 62.44 416.205 64.8667 417.605 67.76C419.005 70.6533 419.705 74.0133 419.705 77.84C419.705 81.9467 419.052 85.68 417.745 89.04C416.532 92.4 414.759 95.2933 412.425 97.72C410.185 100.053 407.479 101.873 404.305 103.18C401.132 104.393 397.678 105 393.945 105H342.985V98.56ZM365.525 98.14H389.605C395.018 98.14 399.219 96.5534 402.205 93.38C405.285 90.2067 406.825 86.1 406.825 81.06V74.9C406.825 69.9533 405.285 65.8933 402.205 62.72C399.219 59.5467 395.018 57.96 389.605 57.96H365.525V98.14ZM365.525 51.1H387.365C392.405 51.1 396.279 49.6533 398.985 46.76C401.692 43.8667 403.045 40.18 403.045 35.7V29.54C403.045 25.06 401.692 21.3733 398.985 18.48C396.279 15.5867 392.405 14.14 387.365 14.14H365.525V51.1Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M428.58 98.56H436L467.78 7.28001H481.08L512.72 98.56H520.14V105H487.1V98.56H499.7L491.02 73.22H452.94L444.26 98.56H456.86V105H428.58V98.56ZM454.9 66.5H488.92L472.54 17.5H471.7L454.9 66.5Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M566.642 106.68C560.482 106.68 554.882 105.513 549.842 103.18C544.802 100.847 540.508 97.4867 536.962 93.1C533.415 88.7134 530.662 83.3933 528.702 77.14C526.742 70.8867 525.762 63.8867 525.762 56.14C525.762 48.02 526.742 40.8333 528.702 34.58C530.662 28.3267 533.368 23.0533 536.822 18.76C540.368 14.4667 544.615 11.2 549.562 8.96C554.508 6.72 559.968 5.60001 565.942 5.60001C572.008 5.60001 577.048 6.62667 581.062 8.68001C585.168 10.64 587.875 13.1133 589.182 16.1H589.742V7.28001H599.822V35.84H589.742V28.56C589.742 23.8933 587.688 20.16 583.582 17.36C579.475 14.4667 574.108 13.02 567.482 13.02C562.535 13.02 558.242 13.9533 554.602 15.82C551.055 17.5933 548.115 20.0667 545.782 23.24C543.542 26.32 541.862 29.96 540.742 34.16C539.715 38.2667 539.202 42.6067 539.202 47.18V64.12C539.202 69.2533 539.855 73.8733 541.162 77.98C542.562 82.0867 544.522 85.6333 547.042 88.62C549.655 91.5133 552.828 93.7533 556.562 95.34C560.295 96.9267 564.542 97.72 569.302 97.72C575.928 97.72 581.482 95.9933 585.962 92.54C590.442 89.0867 593.708 84.7 595.762 79.38L601.222 82.04C600.382 84.7467 599.028 87.5467 597.162 90.44C595.295 93.3334 592.915 95.9933 590.022 98.42C587.222 100.847 583.862 102.853 579.942 104.44C576.022 105.933 571.588 106.68 566.642 106.68Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    <path d="M617.38 98.56H627.88V13.72H617.38V7.28001H650.42V13.72H639.92V60.48H640.48L653.5 45.78L682.9 13.72H671.7V7.28001H701.8V13.72H692.42L662.18 46.34L695.92 98.56H705.58V105H672.12V98.56H682.06L653.78 55.02L639.92 70V98.56H650.42V105H617.38V98.56Z" stroke="white" strokeWidth="10" mask="url(#path-1-outside-1)"/>
    </svg>
    <div className='underline'>
    </div>
</div>
  );
}

export default KickbackSVG;